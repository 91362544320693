jQuery(document).ready(function(){
    //FAQ Dropdowns
    //==================
    jQuery(".accordion__item--description-container").hide();

    jQuery(".accordion__item--title").click(function(){
        jQuery(".accordion__item--description-container").slideUp("slow");
        jQuery(".accordion__item--title .plus").removeClass("minus");
        if(jQuery(this).siblings(".accordion__item--description-container")[0].style.display === 'block') {
            jQuery(this).siblings(".accordion__item--description-container").slideUp("slow");
            jQuery(this).find(".plus").removeClass("minus");
        } else if (jQuery(this).siblings(".accordion__item--description-container")[0].style.display === 'none') {
            jQuery(this).siblings(".accordion__item--description-container").slideDown("slow");
            jQuery(this).find(".plus").addClass("minus");
        }
    });
})
