import $ from 'jquery';

// Swiper documentation: https://swiperjs.com/swiper-api
import {
    Swiper,
    Navigation,
    Pagination,
    Scrollbar,
    EffectCoverflow,
    Thumbs,
    FreeMode
} from 'swiper/swiper.esm.js';

jQuery(document).ready(function() {

    Swiper.use([Navigation, Pagination, Scrollbar, EffectCoverflow, Thumbs, FreeMode]);

    const testimonialsThumbs = new Swiper(".testimonials__thumbs", {
        centeredSlides: true,
        centeredSlidesBounds: true,
        direction: "vertical",
        spaceBetween: 10,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        watchOverflow: true,
        enabled: false,
        breakpoints: {
            768: {
                enabled: true
            }
        }
    });
    const testimonialsSlider = new Swiper(".testimonials__slider", {
        spaceBetween: 10,
        slidesPerView: 1,
        pagination: {
            el: '.testimonials-pagination',
            clickable: true
        },
        thumbs: {
            swiper: testimonialsThumbs,
        },
    });

    testimonialsSlider.on("slideChangeTransitionStart", function () {
        testimonialsThumbs.slideTo(testimonialsSlider.activeIndex);
    });
    testimonialsThumbs.on("transitionStart", function () {
        testimonialsSlider.slideTo(testimonialsThumbs.activeIndex);
    });


    const airframeImageSliderExterior = new Swiper('.airframe-images-slider__exterior', {
        // Optional parameters
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1,
        autoHeight: true,

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 4.5,
            }
        },
        navigation: {
            prevEl: '.exterior-prev-arrow',
            nextEl: '.exterior-next-arrow',
        }
    });

    airframeImageSliderExterior.on('slideChange', function () {
        console.log(this.navigation.nextEl)
        if (this.isBeginning) {
            $(this.navigation.prevEl).hide()
            $('.airframe-slider-prev-gradient').hide()
            $(this.navigation.nextEl).show()
            $('.airframe-slider-gradient').show()
        }
        else if (this.isEnd) {
            $(this.navigation.prevEl).show()
            $('.airframe-slider-prev-gradient').show()
            $(this.navigation.nextEl).hide()
            $('.airframe-slider-gradient').hide()
        }
        else {
            $(this.navigation.prevEl).show()
            $('.airframe-slider-prev-gradient').show()
            $(this.navigation.nextEl).show()
            $('.airframe-slider-gradient').show()
        }
    });
});
