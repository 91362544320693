/* ==========================================================================
    Standard Multi-level and Mobile Navigation
========================================================================== */

jQuery(document).ready(function($){

    let currentScreenSize = window.screen.width;
    // $(window).resize(function () {
    //     currentScreenSize = window.screen.width;
    //     setArrowHandling();
    // })
    // Menu plus icons
    // $('.main-nav li ul').siblings('a').addClass('hasChildren').before('<a class="arrow" href="#"><svg width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.374467 5.9237C0.332589 5.96971 0.276561 5.99544 0.218239 5.99544C0.159918 5.99544 0.103881 5.96971 0.0620029 5.9237C0.0414535 5.90104 0.0251201 5.87393 0.0139671 5.84399C0.00281411 5.81405 -0.00292969 5.78187 -0.00292969 5.74937C-0.00292969 5.71687 0.00281411 5.68471 0.0139671 5.65477C0.0251201 5.62482 0.0414535 5.59772 0.0620029 5.57505L2.41305 2.95126L0.062719 0.418285C0.0422469 0.395736 0.0259792 0.36877 0.0148665 0.338966C0.00375389 0.309163 -0.00197788 0.277137 -0.00197788 0.244781C-0.00197788 0.212425 0.00375389 0.180399 0.0148665 0.150595C0.0259792 0.120792 0.0422469 0.0938052 0.062719 0.0712561C0.104259 0.0245156 0.160423 -0.00170898 0.218955 -0.00170898C0.277488 -0.00170898 0.333643 0.0245156 0.375183 0.0712561L3.0022 2.95126L0.374467 5.9237Z" fill="#E74C39"/></svg></a>');


    function setArrowHandling() {
        $('.arrow').off('click');

        if (currentScreenSize < 768) {
            $('.arrow').on("click", function(e) {
                let submenuOpen = $(this).hasClass('arrowOpen');
                if (!submenuOpen) {
                    $(this).siblings('.sub-menu').slideDown('fast');
                    $(this).addClass('arrowOpen');
                } else {
                    $(this).siblings('.sub-menu').slideUp('fast');
                    $(this).removeClass('arrowOpen');
                }
                $('.arrow').each(function(el) {
                    let itemWidth = $(this).siblings('.hasChildren').innerWidth();
                    $(this).css('left', `calc(${itemWidth}px + 20px)`);
                })
                e.preventDefault();
            });
        } else {
            $('.arrow').each(function(el) {
                let itemWidth = $(this).siblings('.hasChildren').innerWidth();
                $(this).css('left', `calc(${itemWidth}px + 10px)`);
            })
        }


    }
    // setArrowHandling();


    // Mobile menu toggle
    $('.menuToggle').on('click',function(e){
        e.preventDefault();
        $('body').toggleClass('menuOpen');
        $('#search-box').prependTo('.main-nav__links');
        $('.main-nav__links, #search-box').slideToggle('fast');
        $(this).toggleClass('close');
        $('.arrow').each(function(el) {
            let itemWidth = $(this).siblings('.hasChildren').innerWidth();
            $(this).css('left', `calc(${itemWidth}px + 20px)`);
        })
    });

    // Reveal/hide navigation on scroll up/down
    let body = $('body');
    let lastScroll = 0;
    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
            body.removeClass('scrollUp');
        }

        if (!body.hasClass('menuOpen')) {
            if ((currentScroll > lastScroll) && !body.hasClass('scrollDown') && currentScroll > 130) {
                // down
                body.removeClass('scrollUp');
                body.addClass('scrollDown');
            } else if ((currentScroll < lastScroll) && body.hasClass('scrollDown')) {
                // up
                body.removeClass('scrollDown');
                body.addClass('scrollUp');
            }

            lastScroll = currentScroll;
        }
    });

});
